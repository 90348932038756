import axios from "axios";
import { logout, getNewAccessToken } from "../services/authService";
import { jwtDecode } from "jwt-decode";

const backEndUri = process.env.REACT_APP_BACKEND_URI;

export const api = axios.create({
  baseURL: backEndUri,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const guestApi = axios.create({
  baseURL: backEndUri,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      logout();
    }
  }
);

api.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("accessToken");
    config.headers["Authorization"] = `Bearer ${token}`;
    const decoded = jwtDecode(token);

    if (decoded.exp * 1000 < Date.now()) {
      const newToken = await getNewAccessToken();

      localStorage.setItem("accessToken", newToken);
      config.headers["Authorization"] = `Bearer ${newToken}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
