import { Navlink } from "./Navlink";
import { logout } from "../services/authService";
import { Link } from "react-router-dom";
import { useState } from "react";

export function Nav(){
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
    return (
      <nav className="bg-[#1F1E1E] sticky top-0 z-50">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center">
                <img
                  src="/logo.png"
                  className="h-8 w-auto rounded-md"
                  alt="Sensor Logo"
                />
              </Link>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Navlink to="/" name="Dashboard" />
                <Navlink to="/assign-mac-ssid" name="MAC + SSID" />
              </div>
            </div>
            <div className="hidden md:block">
              <button
                onClick={logout}
                className="text-red-500 border border-red-400 rounded-md px-3 py-2 text-sm font-medium hover:text-red-400 hover:border-red-400 transition-colors"
              >
                Logout
              </button>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={toggleMenu}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded={isOpen}
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu, show/hide based on menu state. */}
        <div
          className={`md:hidden ${isOpen ? "block" : "hidden"}`}
          id="mobile-menu"
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Navlink to="/" name="Dashboard" mobile />
            <Navlink to="/assign-mac-ssid" name="MAC + SSID" mobile />
            <button
              onClick={logout}
              className="w-1/4 text-left text-red-500 border border-red-400 rounded-md px-3 py-2 text-base font-medium hover:text-red-400 hover:border-red-400 transition-colors mt-2"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    );
}