import React, { useEffect, useState } from "react";
import { Input } from "../../Components/Input";
import { Colon } from "../../Components/Colon";
import { Button } from "../../Components/Button";
import { api } from "../../api/api";

export function CreateMac(){
    const [loading, setLoading] = useState(false);
    const [ssid, setSsid] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState({ error: false, msg: "" });

    const [isMacValid, setIsMacValid] = useState(true);
    const [mac, setMac] = useState({
      mac1: "",
      mac2: "",
      mac3: "",
      mac4: "",
      mac5: "",
      mac6: "",
    });

    const macs = ["mac1", "mac2", "mac3", "mac4", "mac5", "mac6"];

    const handleMac = (e) => {
      const { name, value } = e.target;
      const valueUpper = value.toUpperCase();

      if (/^[0-9A-F]*$/.test(valueUpper) && valueUpper.length < 3) {
        setMac((prev) => ({
          ...prev,
          [name]: valueUpper,
        }));
      } else {
        return;
      }

      if (valueUpper.length === 2) {
        const index = macs.indexOf(name);
        moveFocus(macs[index + 1]);
      } else if (valueUpper.length === 0) {
        const index = macs.indexOf(name);
        moveFocus(macs[index - 1]);
      }
    };

    const moveFocus = (name) => {
      const inputElement = document.querySelector(`input[name="${name}"]`);
      if (inputElement) {
        inputElement.focus();
      }
    };

    const handlePassword = (e) => {
      setPassword(e.target.value);
    };

    const handleSsid = (e) => {
      setSsid(e.target.value);
    };

    const handleKeyDown = (e) => {
      if (e.key === "Backspace" && e.target.value.length === 0) {
        const index = macs.indexOf(e.target.name);
        moveFocus(macs[index - 1]);
      }
    };

    const handleSubmit = async () => {
      const arrayOfMac = Object.values(mac);

      arrayOfMac.forEach((m) => {
        if (m.length !== 2) {
          setIsMacValid(false);
          setMsg({ error: true, msg: "Invalid MAC" });
          return;
        }
      });

      if (!isMacValid) return;

      if (ssid === "" || password === "") {
        return;
      }

      const macAddress = arrayOfMac.join(":");
      const data = {
        mac: macAddress,
        ssid,
        password,
      };

      try {
        setLoading(true);
        const res = await api.post("/ssid/post-mac-ssid", data);
        setLoading(false);
        if (res?.status === 201) {
          setSsid("");
          setPassword("");
          setMac({
            mac1: "",
            mac2: "",
            mac3: "",
            mac4: "",
            mac5: "",
            mac6: "",
          });

          setMsg({ error: false, msg: "Successfully Saved" });
        } else {
          setMsg({ error: true, msg: "MAC already exist" });
        }
      } catch (error) {
        setMsg({ error: true, msg: "Something went wrong" });
      }

      setLoading(false);
      setTimeout(() => {
        setMsg({ msg: "" });
      }, 5000);
    };

    useEffect(() => {
      document.title = "Create MAC & SSID";
    });

    return (
      <>
        <div className="h-screen flex justify-center items-center bg-[#282928] text-[#DDDCDD]">
          <div className="flex flex-col border py-20 px-10 rounded-3xl border-gray-700 shadow-2xl bg-[#393839]">
            <div className="w-full max-w-xl">
              <div
                className={`text-center mb-4 ${
                  msg.error ? "text-red-500" : "text-green-500"
                }`}
              >
                {msg.msg}
              </div>

              <div className="text-center mb-4 text-xl">MAC</div>
              <div className="flex flex-wrap justify-start gap-2 mb-8">
                {[1, 2, 3, 4, 5, 6].map((num) => (
                  <React.Fragment key={num}>
                    {num > 1 && (
                      <span className="self-center text-xl">
                        {" "}
                        <Colon />
                      </span>
                    )}
                    <div className="w-12 sm:w-14">
                      <Input
                        type="text"
                        name={`mac${num}`}
                        value={mac[`mac${num}`]}
                        onChange={handleMac}
                        onKeyDown={handleKeyDown}
                        autoComplete="off"
                        className="text-center"
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>

              <div className="mb-6">
                <Input label="SSID" value={ssid} onChange={handleSsid} />
              </div>

              <div className="mb-6">
                <Input
                  label="PASSWORD"
                  value={password}
                  onChange={handlePassword}
                />
              </div>

              <div className="text-center">
                {loading ? (
                  <span className="inline-block px-4 py-2 bg-gray-500 text-white rounded">
                    Sending...
                  </span>
                ) : (
                  <Button
                    name="Submit"
                    onClick={handleSubmit}
                    className="w-full sm:w-auto"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}