import React, { useEffect, useState } from "react";
import { Input } from "../../Components/Input";
import { Button } from "../../Components/Button";
import { RedButton } from "../../Components/RedButton";
import { Colon } from "../../Components/Colon";

const EditForm = ({ item, onSave, isEditing }) => {
  const [formData, setFormData] = useState({ ...item });
  const [msg, setMsg] = useState({ error: false, msg: "" });
  const [isMacValid, setIsMacValid] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    document.title = "Edit MAC and SSID";
  });

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && e.target.value.length === 0) {
      const index = macs.indexOf(e.target.name);
      moveFocus(macs[index - 1]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const arrayOfMac = Object.values(mac);

    arrayOfMac.forEach((m) => {
      if (m.length !== 2) {
        setIsMacValid(false);
        setMsg({ error: true, msg: "Invalid MAC" });
        return;
      }
    });

    if (!isMacValid) return;

    const macAddress = arrayOfMac.join(":");
    formData.mac = macAddress;
    if (
      formData.ssid === "" ||
      formData.password === "" ||
      formData.mac === ""
    ) {
      return;
    }

    onSave(formData);
  };

  const arrayOfMacs = formData.mac.split(":");

  const [mac, setMac] = useState({
    mac1: arrayOfMacs[0],
    mac2: arrayOfMacs[1],
    mac3: arrayOfMacs[2],
    mac4: arrayOfMacs[3],
    mac5: arrayOfMacs[4],
    mac6: arrayOfMacs[5],
  });

  const macs = ["mac1", "mac2", "mac3", "mac4", "mac5", "mac6"];

  const handleMac = (e) => {
    const { name, value } = e.target;
    const valueUpper = value.toUpperCase();

    if (/^[0-9A-F]*$/.test(valueUpper) && valueUpper.length < 3) {
      setMac((prev) => ({
        ...prev,
        [name]: valueUpper,
      }));
    } else {
      return;
    }

    if (valueUpper.length === 2) {
      const index = macs.indexOf(name);
      moveFocus(macs[index + 1]);
    } else if (valueUpper.length === 0) {
      const index = macs.indexOf(name);
      moveFocus(macs[index - 1]);
    }
  };

  const moveFocus = (name) => {
    const inputElement = document.querySelector(`input[name="${name}"]`);
    if (inputElement) {
      inputElement.focus();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full max-w-xl mx-auto px-4 py-8">
      <div className="w-full mb-8">
        <RedButton
          type="button"
          name="Back"
          onClick={() => isEditing(null)}
          className="w-full sm:w-auto"
        />
      </div>

      {msg.msg && <span className="mb-4 text-red-500">{msg.msg}</span>}

      <form onSubmit={handleSubmit} className="w-full">
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            MAC Address
          </label>
          <div className="flex flex-wrap gap-2 items-center">
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <React.Fragment key={num}>
                {num > 1 && <span className="text-gray-500"> <Colon/> </span>}
                <div className="w-14">
                  <Input
                    type="text"
                    name={`mac${num}`}
                    value={mac[`mac${num}`]}
                    onChange={handleMac}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    className="text-center"
                    maxLength={2}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <Input
            type="text"
            name="ssid"
            label="SSID"
            value={formData.ssid}
            onChange={handleChange}
          />
        </div>

        <div className="mb-6">
          <Input
            type="text"
            label="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>

        <Button type="submit" name="Update" className="w-full" />
      </form>
    </div>
  );
};

export default EditForm;
