export const Status = ({ status }) => {

  return status ? (
    <div className="flex items-center space-x-2">
      <span className="flex h-3 w-3">
        <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
      </span>
      <span className="text-sm font-medium text-green-500">Active</span>
    </div>
  ) : (
    <div className="flex items-center space-x-2 mt-4">
      <span className="flex h-3 w-3">
        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
      </span>
      <span className="text-sm font-medium text-red-500">Inactive</span>
    </div>
  );
};
