import { Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { CreateMac } from "./pages/CreateMac/CreateMac";
import { Nav } from "./Components/Nav";
import Login from "./pages/Login";
import { AuthProvider, useAuth } from "./Context/AuthContext";
import { Loading } from "./Components/Loading";
import { useEffect } from "react";
import { logout } from "./services/authService";

function App() {
  const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.newValue === null) {
        logout()
      }
    };

    // storage event listener
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

    if (isLoading) {
      return (
        <>
          <div className="h-screen flex justify-center items-center bg-[#282928] text-[#393839]">
            <Loading />
          </div>
        </>
      );
    }

    return isAuthenticated ? (
      <>
        <div className="bg-[#282928] text-[#DDDCDD]">
          <Nav />
          {children}
        </div>
      </>
    ) : (
      <Navigate to="/login" replace />
    );
  };

  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/login" Component={Login} />

          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/assign-mac-ssid"
            element={
              <PrivateRoute>
                <CreateMac />
              </PrivateRoute>
            }
          />
          
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
