
const DeleteWarningPopup = ({ isOpen, onDelete, onCancel }) => {
  if (!isOpen) return null;

   return (
     <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-75">
       <div className="bg-white p-6 rounded-lg shadow-lg w-96">
         <h2 className="text-lg font-semibold text-gray-800">
           Are you sure you want to delete this item?
         </h2>
         <div className="mt-4 flex justify-end space-x-4">
           <button
             onClick={onCancel}
             className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
           >
             No
           </button>
           <button
             onClick={onDelete}
             className="px-4 py-2 bg-red-500 text-white rounded-md"
           >
             Yes
           </button>
         </div>
       </div>
     </div>
   );
};

export default DeleteWarningPopup;
