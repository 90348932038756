import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import {Input} from "../Components/Input"
import { Button } from "../Components/Button";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const { login, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (isAuthenticated) {
        navigate("/");
      }
      document.title = "Login";
    }, [isAuthenticated, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const success = await login(email, password);
            if (success) {
                navigate('/'); // Redirect to dashboard on successful login
            } else {
                setError('Login failed. Please check your credentials.');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
      <div className="h-screen flex justify-center items-center bg-[#282928] text-[#393839]">
        <div className="flex flex-col border py-20 px-10 rounded-3xl border-gray-700 shadow-2xl bg-[#393839]">
          <div
            className="flex justify-center mb-10"
            style={{ color: "#02DAC4" }}
          >
            <img
              src="/logo.png"
              className="h-20 rounded-lg"
              alt="Sensor Logo"
            />
          </div>
          <div className="flex justify-center text-red-600 mb-4">
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Input
                type="text"
                id="email"
                label="Username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <Input
                type="password"
                id="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="w-full flex justify-center">
              <Button name="Login" />
            </div>
          </form>
        </div>
      </div>
    );
};

export default Login;