import { Link, useLocation } from "react-router-dom";

export const Navlink = ({ name, to, onClick, extraClass }) => {
  const location = useLocation();
  let classes =
    "block py-2 px-3 rounded text-white ";
  let style = {}
  if (location.pathname === to) {
    style = { color: "#02DAC4" };
  }

  return (
    <Link to={to} className={classes} onClick={onClick} style={style}>
      {name}
    </Link>
  );
};
