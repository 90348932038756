import { useEffect, useState } from "react";
import { api } from "../../api/api";
import EditForm from "./EditMac";
import { MacTable } from "./MacTable";

export function Dashboard() {
  const [editingItem, setEditingItem] = useState(false);

  const handleSave = async (updatedItem) => {
    try {
      await api.post("/ssid/update", updatedItem);
      setData((prevData) =>
        prevData.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        )
      );
    } catch (err) {
      console.log(err);
    }

    setEditingItem(false);
  };

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const res = await api.get("/ssid/get-assigned-macs");
    setData(res.data.macs);
    setIsLoading(false);
  };

  useEffect(() => {
    document.title = "Dashboard";
    fetchData();
  }, []);

  return (
    <>
      <div className="min-h-screen ">
        {editingItem ? (
          <EditForm
            item={editingItem}
            onSave={handleSave}
            isEditing={setEditingItem}
          />
        ) : (
          <MacTable
            data={data}
            refetchData={fetchData}
            setEditingItem={setEditingItem}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
}
