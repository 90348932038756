export const Button = ({ name, onClick, value }) => {
  return (
    <button
      className="text-black py-1 px-2 rounded"
      style={{ background: "#02DAC4" }}
      onClick={onClick}
      value={value}
    >
      {name}
    </button>
  );
};