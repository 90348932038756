import { Button } from "../../Components/Button";
import { Loading } from "../../Components/Loading";
import { Status } from "../../Components/Status";
import { Input } from "../../Components/Input";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import DeleteWarningPopup from "../../Components/DeleteWarning";

export const MacTable = ({ data, refetchData, setEditingItem, isLoading }) => {
  const handleEdit = (e) => {
    const item = JSON.parse(e.target.value);
    setEditingItem(item);
  };

  const [checkedItems, setCheckedItems] = useState(
    data.reduce((acc, item) => {
      acc[item.id] = false; // Initialize all checkboxes to unchecked
      return acc;
    }, {})
  );

  // Handler for toggling the "Select All" checkbox
  const handleSelectAll = (event) => {
    const newCheckedState = {};
    data.forEach((item) => {
      newCheckedState[item.id] = event.target.checked;
    });
    setCheckedItems(newCheckedState); // Update state for all checkboxes
  };

  // Handler for toggling individual checkboxes
  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the checked state of the individual checkbox
    }));
  };

  // Check if all checkboxes are checked
  const areAllChecked = data.every((item) => checkedItems[item.id]);

  const getCheckedIdsAndDelete = () => {
    const checkIds = Object.entries(checkedItems)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);
    deleteItems(checkIds);
  };

  useEffect(() => {
    document.title = "MAC Table";
  })

  const deleteItems = async (ids) => {
    try {
      await api.delete("/ssid/delete-items", { data: { ids } });
    } catch (error) {}
    refetchData();
  };


  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = (e) => {
    setIsOpen(false);
    getCheckedIdsAndDelete();
  };

  const handleCancel = () => {
    setIsOpen(false);
  };


  return (
    <>
      <DeleteWarningPopup
        isOpen={isOpen}
        onDelete={handleDelete}
        onCancel={handleCancel}
      />

      <div className="p-4 overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden border border-gray-700 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-[#282928]">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    <div className="flex items-center space-x-2">
                      <Input
                        type="checkbox"
                        checked={areAllChecked}
                        onChange={handleSelectAll}
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                      />
                      <span className="hidden sm:inline">Sl. No.</span>
                      <button
                        onClick={() => setIsOpen(true)}
                        className="px-2 py-1 text-xs text-red-500 border border-red-400 rounded hover:text-red-400 hover:border-red-400 transition-colors"
                      >
                        Delete
                      </button>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    MAC
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium text-gray-400 uppercase tracking-wider hidden sm:table-cell"
                  >
                    SSID
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium text-gray-400 uppercase tracking-wider hidden md:table-cell"
                  >
                    Password
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-[#282928] divide-y divide-gray-700">
                {isLoading ? (
                  <tr>
                    <td colSpan="6" className="px-3 py-4 text-center">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-white">
                        <div className="flex items-center space-x-2">
                          <Input
                            type="checkbox"
                            checked={checkedItems[item.id]}
                            onChange={() => handleCheckboxChange(item.id)}
                            className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                          />
                          <span className="hidden sm:inline">{index + 1}</span>
                        </div>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-white">
                        {item.mac}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-white hidden sm:table-cell">
                        {item.ssid}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-white hidden md:table-cell">
                        {item.password}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-white">
                        <Status status={item.status} />
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-white">
                        <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
                          <Button
                            name="Edit"
                            value={JSON.stringify(item)}
                            onClick={handleEdit}
                            className="w-full sm:w-auto"
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
