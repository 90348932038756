import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById('root'));
const isProduction = process.env.REACT_APP_ENV !== "development";

const renderApp = isProduction ? (
  <BrowserRouter>
    <App />
  </BrowserRouter>
) : (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

root.render(renderApp);