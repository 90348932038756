export const Input = ({ label, id, type = 'text', placeholder, value, onChange, className, ...props }) => {
  return (
    <div className="">
      <label
        htmlFor={id}
        className="block text-sm font-medium mb-2 text-[#DDDCDD]"
      >
        {label}
      </label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`w-full px-3 text-black py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`}
        {...props}
      />
    </div>
  );
}