import { createContext, useContext, useEffect, useState } from "react";
import { api, guestApi } from "../api/api";
import { Navigate } from "react-router-dom";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleLogout = async () => {
      try {
        await api.post("/auth/logout");
      } catch (error) {
        console.error("Logout failed:", error);
      } finally {
        localStorage.removeItem("accessToken");
        delete api.defaults.headers.common["Authorization"];
        setIsAuthenticated(false);
        <Navigate to="/login" />;
      }
    };

    useEffect( () => {
        const verifyToken = async () => {
          const token = localStorage.getItem("accessToken");
          if (token) setIsAuthenticated(true);
          else handleLogout();
        };

        verifyToken();
        setIsLoading(false);
    },[])

    const logout = async () => {
      await handleLogout();
    };

    const login = async (email, password) => {
      try {
        const response = await guestApi.post("/auth/login", {
          email,
          password,
        });
        const { token } = response.data;

        localStorage.setItem("accessToken", token);
        guestApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setIsAuthenticated(true);

        return true;
      } catch (error) {
        console.error("Login failed:", error);
        return false;
      }

    };

    return (
      <AuthContext.Provider
        value={{
          isAuthenticated,
          setIsAuthenticated,
          login,
          logout,
          isLoading,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);